import React from 'react';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import OrderConfirmationPage from '~/components/Order/Confirmation/Page';

const OrderConfirmation = ({ number, id }) => {
  const translate = useTranslate();

  return (
    <Layout hideWidget>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage orderNumber={number} checkoutSessionId={id} />
    </Layout>
  );
};

export default OrderConfirmation;
